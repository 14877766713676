import { Card } from '@analog/ui';
import { SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { SortColumnsBlock, arrayOfSize } from 'utils';

import useBlockList from './useBlockList';

const BlockList = () => {
  const {
    COLUMNS,
    PageController,
    blockData,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
    FilterController,
  } = useBlockList();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        {/* <Heading
          title="Blocks"
          subTitle="Examine all the details about the blocks, including block id, status, hash, etc."
        /> */}
        <div className="flex gap-2">
          <SearchBox defaultSearchType={'All'} />
          {FilterController}
        </div>
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <Card className="pb-6">
          <span className="text-white text-xl">Blocks</span>
          <Table
            columns={COLUMNS}
            hasHeader
            classNames={{
              row: 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(110px,15%)_minmax(70px,10%)_minmax(115px,10%)_minmax(100px,20%)_minmax(100px,20%)_minmax(100px,20%)]',
              columns: 'justify-start',
            }}
            data={!isLoading ? blockData : arrayOfSize(pageSize)}
            sortOptions={SortColumnsBlock}
            sortOrder={sortOrder}
            onSort={onSort}
            isLoading={isLoading}
          />
          <Skeleton.Provider isLoading={isLoading}>
            {(isLoading || blockData?.length) && PageController}
          </Skeleton.Provider>
        </Card>
      </div>
    </>
  );
};

export default BlockList;
