import { DetailsListNavigation, HashIcon, Icon } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon, Text, TruncatedText } from 'components';
import JsonTable from 'components/common/JsonTable';
import { FontColors } from 'consts';
import { ExtrinsicDetailsQuery } from 'gql';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { classes, formatAmount, statusColor, timeAgo } from 'utils';
interface OverviewDetailsProps {
  extrinsicDetailsData: ExtrinsicDetailsQuery | undefined;
}
const OverviewDetails = ({ extrinsicDetailsData }: OverviewDetailsProps) => {
  const navigate = useNavigate();
  return [
    {
      icon: <Icon icon={allImgPaths.dataArrowsIcon} />,
      label: 'Extrinsic',
      value: (
        <div className="flex items-center gap-2">
          {extrinsicDetailsData?.extrinsicDetails?.id}
          <DetailsListNavigation
            handlePrevDetails={() => {
              if (extrinsicDetailsData?.extrinsicDetails?.previousExtrinsic)
                navigate(
                  `${routes.extrinsic}/${extrinsicDetailsData?.extrinsicDetails?.previousExtrinsic}`
                );
            }}
            disablePrev={!extrinsicDetailsData?.extrinsicDetails?.previousExtrinsic}
            handleNextDetails={() => {
              if (extrinsicDetailsData?.extrinsicDetails?.nextExtrinsic)
                navigate(
                  `${routes.extrinsic}/${extrinsicDetailsData?.extrinsicDetails?.nextExtrinsic}`
                );
            }}
            disableNext={!extrinsicDetailsData?.extrinsicDetails?.nextExtrinsic}
          />
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Block Number',
      value: (
        <Text
          color={FontColors.PURPLE}
          extraClass="cursor-pointer"
          onClick={() =>
            navigate(`${routes.block}/${extrinsicDetailsData?.extrinsicDetails?.block_number}`)
          }
        >
          {extrinsicDetailsData?.extrinsicDetails?.block_number}
        </Text>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Result',
      value: (
        <div
          className={classes(
            `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
            statusColor(extrinsicDetailsData?.extrinsicDetails?.result || '')
          )}
        >
          <span
            className={
              'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
            }
          >
            {extrinsicDetailsData?.extrinsicDetails?.result || '-'}
          </span>
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Timestamp',
      value: extrinsicDetailsData?.extrinsicDetails?.created_at
        ? `${timeAgo(new Date(extrinsicDetailsData?.extrinsicDetails?.created_at))} (${moment
            .utc(extrinsicDetailsData?.extrinsicDetails?.created_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.phaseIcon} />,
      label: 'Action',
      value: (
        <Text extraClass="text-white capitalize">
          {extrinsicDetailsData?.extrinsicDetails?.section}(
          {extrinsicDetailsData?.extrinsicDetails?.method})
        </Text>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.heart2Icon} />,
      label: 'Life Time',
      collapsible: true,
      value: (
        <JsonTable
          index={extrinsicDetailsData?.extrinsicDetails?.id.toString() as string}
          jsonData={extrinsicDetailsData?.extrinsicDetails?.life_time}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Extrinsic Hash',
      value: (
        <TruncatedText
          originalText={extrinsicDetailsData?.extrinsicDetails?.hash as string}
          withCopyButton
          width="100%"
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.senderIcon} />,
      label: 'Sender/Signer',
      value: (
        <Text
          onClick={() =>
            navigate(`${routes.account}/${extrinsicDetailsData?.extrinsicDetails?.signer}`)
          }
        >
          <HashIcon
            value={extrinsicDetailsData?.extrinsicDetails?.signer}
            className="text-white cursor-pointer"
            copyable
          />
        </Text>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.transferIcon} />,
      label: 'Assets Transfer',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          {formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0')}
        </div>
      ),
      enable:
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0') !==
          '0 ANLOG' &&
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0') !==
          '0.00 ANLOG',
    },
    {
      icon: <Icon icon={allImgPaths.mathIcon} />,
      label: 'Estimated Fee',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          {formatAmount(extrinsicDetailsData?.extrinsicDetails?.estimated_fee || '0')}
        </div>
      ),
      enable:
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.estimated_fee || '0') !== '0 ANLOG' &&
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.estimated_fee || '0') !== '0.00 ANLOG',
    },
    {
      icon: <Icon icon={allImgPaths.walletIcon} />,
      label: 'Used fee',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-5 h-5" />
          {formatAmount(extrinsicDetailsData?.extrinsicDetails?.used_fee || '0')}
        </div>
      ),
      enable:
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.used_fee || '0') !== '0 ANLOG' &&
        formatAmount(extrinsicDetailsData?.extrinsicDetails?.used_fee || '0') !== '0.00 ANLOG',
    },
    {
      icon: <Icon icon={allImgPaths.clipboardCloseIcon} />,
      label: 'Nonce',
      value: extrinsicDetailsData?.extrinsicDetails?.nonce,
    },
    {
      icon: <Icon icon={allImgPaths.parametersIcon} />,
      label: 'Parameters',
      collapsible: true,
      value: (
        <JsonTable
          index={extrinsicDetailsData?.extrinsicDetails?.id.toString() as string}
          jsonData={extrinsicDetailsData?.extrinsicDetails?.args}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.graphIcon} />,
      label: 'Signature',
      value: (
        <TruncatedText
          originalText={extrinsicDetailsData?.extrinsicDetails?.signature || ''}
          width="80%"
        />
      ),
    },
  ];
};

export default OverviewDetails;
