import React, { ReactNode } from 'react';

import { DynamicObject } from 'types';

const renderRows = (obj: DynamicObject) => {
  return Object.entries(obj).map(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      return (
        <React.Fragment key={key}>
          <tr className="text-sm md:text-base border border-solid border-darkGray border-r-0 w-full">
            <td className="p-2 text-offWhite font-bold whitespace-nowrap">{key}</td>
            <td colSpan={2}>
              <table>
                <tbody>{renderRows(value)}</tbody>
              </table>
            </td>
          </tr>
        </React.Fragment>
      );
    } else {
      return (
        <tr key={key} className="">
          <td className="p-2 text-offWhite border border-solid border-darkGray whitespace-nowrap">
            {key}
          </td>
          <td className="p-2 text-pink border border-solid border-darkGray break-all">
            {(value as ReactNode) || 'null'}
          </td>
        </tr>
      );
    }
  });
};

const VerticalTable = ({ jsonData }: { jsonData: DynamicObject }) => {
  return (
    <table border={1} cellSpacing={10} className="w-full table-fixed">
      <tbody>{renderRows(jsonData)}</tbody>
    </table>
  );
};

export default VerticalTable;
