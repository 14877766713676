import ExtrinsicsColumns from 'components/common/ExtrinsicsColumns';
import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useGetExtrinsicsQuery } from 'gql';
import { usePagination, useSortBy } from 'hooks';
import { useParams } from 'react-router-dom';

const useExtrinsicList = () => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();

  const EXTRINSICS_COLUMNS = ExtrinsicsColumns();

  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { data: extrinsicData, loading: isExtrinsicsLoading } = useGetExtrinsicsQuery({
    variables: {
      sessionKey,
      signer: id,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
    },
    onCompleted(data) {
      setTotalDataCount(data.getExtrinsics.count);
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  });

  return {
    PageController,
    pageSize,
    onSort,
    sortOrder,
    extrinsicData,
    isExtrinsicsLoading,
    EXTRINSICS_COLUMNS,
  };
};
export default useExtrinsicList;
