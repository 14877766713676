import { useMemo } from 'react';

import { SearchBox } from 'components';

import { LatestBlocks } from './components/LatestBlocks/LatestBlocks';
import { LatestExtrinsics } from './components/LatestExtrinsics/LatestExtrinsics';
import { MembersTable } from './components/MembersTable/MembersTable';
import { TaskTable } from './components/TaskTable/TaskTable';
import Telemetry from './components/Telemetry/Telemetry';
import { useTelemetryData } from './components/Telemetry/useTelemetryData';

const HomePage = () => {
  const { supportedChain, telemetryData, supportedChainLoading, telemetryLoading } =
    useTelemetryData();

  const LatestBlocksComponent = useMemo(() => <LatestBlocks />, []);
  const LatestExtrinsicsComponent = useMemo(() => <LatestExtrinsics />, []);
  const LatestTasksComponent = useMemo(() => <TaskTable />, []);
  return (
    <>
      <div className="flex flex-col gap-4 md:gap-[21px]">
        {/* <Heading title="Analog Blockchain Explorer" /> */}
        <SearchBox defaultSearchType={'All'} />
      </div>

      <div className="flex flex-col mt-[45px] gap-8 lg:gap-10">
        <Telemetry
          telemetryData={telemetryData}
          isTelemetryLoading={telemetryLoading}
          supportedChain={supportedChain?.supportedChains as string[]}
          isSupportedChainLoading={supportedChainLoading}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-10">
          {LatestBlocksComponent}
          {LatestExtrinsicsComponent}
        </div>
        {LatestTasksComponent}
        <MembersTable />
      </div>
    </>
  );
};

export default HomePage;
