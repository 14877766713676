/* eslint-disable import/order */

import addIcon from './icons/add.svg';
import rightArrowIcon from './icons/arrow-right.svg';
import caretIcon from './icons/caret.svg';
import closeIcon from './icons/close.svg';
import notFoundIcon from './icons/cloud-remove.svg';
import appLogo from './icons/company-logo.svg';
import copyIcon from './icons/copy.svg';
import dataArrowsIcon from './icons/data-arrows.svg';
import defaultNetwork from './icons/defaultNetwork.svg';
import downArrowIcon from './icons/down-arrow.svg';
import filterIcon from './icons/filter.svg';
import filterOnIcon from './icons/filterOn.svg';
import hashTagPurpleIcon from './icons/hashtag-purple.svg';
import heartIcon from './icons/heart.svg';
import layerIcon from './icons/layer.svg';
import leftCaretIcon from './icons/left-caret.svg';
import loaderIcon from './icons/loader.svg';
import menuIcon from './icons/menu-icon.svg';
import offlineIcon from './icons/offline.svg';
import online2Icon from './icons/online-2.svg';
import onlineIcon from './icons/online.svg';
import profileIcon from './icons/profile.svg';
import rightCaretIcon from './icons/right-caret.svg';
import searchIcon from './icons/search.svg';
import sortIcon from './icons/sort.svg';
import subtractIcon from './icons/subtract.svg';
import taskIcon from './icons/task.svg';
import gradientTickCircleIcon from './icons/tick-circle-gradient.svg';
import tickCircleIcon from './icons/tick-circle.svg';
import tickIcon from './icons/tick.svg';
import upArrowIcon from './icons/up-arrow.svg';
import lowerBlob from './images/lower-blob.svg';
import upperBlob from './images/upper-blob.svg';

//Footer
import discordIcon from './icons/footer/discord.svg';
import mediumIcon from './icons/footer/medium.svg';
import telegramIcon from './icons/footer/telegram.svg';
import xIcon from './icons/footer/x.svg';

//Home
import arbitrumIcon from './icons/home/arbitrum.svg';
import astarIcon from './icons/home/astar.svg';
import binanceIcon from './icons/home/binance.svg';
import bitcoinIcon from './icons/home/bitcoin.svg';
import ethereumIcon from './icons/home/ethereum.svg';
import optimismIcon from './icons/home/optimism.svg';
import polygonFlipIcon from './icons/home/polygon-flip.svg';
import polygonIcon from './icons/home/polygon.svg';

// Block
import cube3DIcon from './icons/blocks/3dcube.svg';
import clockIcon from './icons/blocks/block-time.svg';
import calendarTickIcon from './icons/blocks/calendar-tick.svg';
import extrinsicsRootIcon from './icons/blocks/extrinsics-root.svg';
import hashTagDotIcon from './icons/blocks/hashtag-dot.svg';
import hashTagOutlineIcon from './icons/blocks/hashtag-outline.svg';
import hashTagIcon from './icons/blocks/hashtag.svg';
import stateRootIcon from './icons/blocks/state-root.svg';
import timerIcon from './icons/blocks/timer-stamp.svg';

// Extrinsics
import bubbleIcon from './icons/extrinsics/bubble.svg';
import clipboardCloseIcon from './icons/extrinsics/clipboard-close.svg';
import clipboardTickIcon from './icons/extrinsics/clipboard-tick.svg';
import cubeDashedIcon from './icons/extrinsics/dash-cube.svg';
import senderIcon from './icons/extrinsics/directbox-send.svg';
import walletIcon from './icons/extrinsics/empty-wallet-change.svg';
import graphIcon from './icons/extrinsics/graph.svg';
import heart2Icon from './icons/extrinsics/heart.svg';
import mathIcon from './icons/extrinsics/math.svg';
import parametersIcon from './icons/extrinsics/parameters.svg';
import transferIcon from './icons/extrinsics/transfer.svg';

// Tasks
import destinationAddress from './icons/destination-address.svg';
import destinationHash from './icons/destination-hash.svg';
import linkIcon from './icons/link.svg';
import ageIcon from './icons/tasks/age.svg';
import chainIcon from './icons/tasks/chain.svg';
import cycleIcon from './icons/tasks/cycle.svg';
import dataSourceIcon from './icons/tasks/data-source.svg';
import globeIcon from './icons/tasks/globe.svg';
import infoIcon from './icons/tasks/info.svg';
import jsonIcon from './icons/tasks/json.svg';
import periodIcon from './icons/tasks/period.svg';
import phaseIcon from './icons/tasks/phase.svg';
import sourceNetworkIcon from './icons/tasks/source-network.svg';
import taskDefinitionIcon from './icons/tasks/task-definition.svg';
import TaskErrorIcon from './icons/tasks/task-error.svg';
import taskStatusIcon from './icons/tasks/task-status.svg';
import unlinkIcon from './icons/tasks/unlink.svg';

// Validators
import addressIcon from './icons/validators/address.svg';
import calendarIcon from './icons/validators/calendar.svg';
import globalSearchIcon from './icons/validators/global-search.svg';
import rewardIcon from './icons/validators/reward.svg';
import verifyIcon from './icons/validators/verify.svg';

// shards
import shardNetworkIcon from './icons/Shards/shardNetworkIcon.svg';
import shardSignature from './icons/Shards/signature.svg';
import taskStatus from './icons/Shards/taskStatus.svg';

// Accounts
import dollarIcon from './icons/accounts/dollar.svg';
import flagsIcon from './icons/accounts/flags.svg';

//Telemetry
import greenTickCircleIcon from './icons/green-tick-circle.svg';
import blockIcon from './icons/telemetry/blocks.svg';
import holderIcon from './icons/telemetry/holder.svg';
import inflationIcon from './icons/telemetry/inflation.svg';
import signIcon from './icons/telemetry/sign.svg';
import stakeIcon from './icons/telemetry/stake.svg';

const allImgPaths = (() => ({
  greenTickCircleIcon,
  blockIcon,
  stakeIcon,
  signIcon,
  holderIcon,
  inflationIcon,
  defaultNetwork,
  appLogo,
  upperBlob,
  lowerBlob,
  mediumIcon,
  discordIcon,
  telegramIcon,
  xIcon,
  searchIcon,
  upArrowIcon,
  downArrowIcon,
  arbitrumIcon,
  astarIcon,
  ethereumIcon,
  optimismIcon,
  polygonIcon,
  rightArrowIcon,
  layerIcon,
  profileIcon,
  dataArrowsIcon,
  caretIcon,
  menuIcon,
  closeIcon,
  sortIcon,
  binanceIcon,
  polygonFlipIcon,
  copyIcon,
  onlineIcon,
  online2Icon,
  offlineIcon,
  heartIcon,
  taskIcon,
  bitcoinIcon,
  notFoundIcon,
  tickCircleIcon,
  gradientTickCircleIcon,
  rightCaretIcon,
  leftCaretIcon,
  tickIcon,
  hashTagOutlineIcon,
  timerIcon,
  clockIcon,
  hashTagIcon,
  hashTagDotIcon,
  stateRootIcon,
  extrinsicsRootIcon,
  cube3DIcon,
  hashTagPurpleIcon,
  calendarTickIcon,
  heart2Icon,
  cubeDashedIcon,
  bubbleIcon,
  senderIcon,
  transferIcon,
  mathIcon,
  walletIcon,
  clipboardCloseIcon,
  clipboardTickIcon,
  parametersIcon,
  graphIcon,
  ageIcon,
  chainIcon,
  cycleIcon,
  dataSourceIcon,
  periodIcon,
  taskDefinitionIcon,
  taskStatusIcon,
  TaskErrorIcon,
  phaseIcon,
  sourceNetworkIcon,
  globeIcon,
  jsonIcon,
  infoIcon,
  unlinkIcon,
  linkIcon,
  rewardIcon,
  verifyIcon,
  globalSearchIcon,
  calendarIcon,
  addressIcon,
  addIcon,
  subtractIcon,
  loaderIcon,
  taskStatus,
  shardSignature,
  shardNetworkIcon,
  flagsIcon,
  dollarIcon,
  destinationAddress,
  destinationHash,
  filterIcon,
  filterOnIcon,
}))();

export default allImgPaths;
