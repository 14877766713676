import { useWindowSize } from '@analog/ui/src/utils/useWindowSize';
import { Card, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useListEventsQuery } from 'gql';
import { usePagination } from 'hooks';
import { useParams } from 'react-router-dom';
import { arrayOfSize } from 'utils';

import EventColumn from './EventColumn';

function ExtrinsicEventList() {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();
  const { isMobile } = useWindowSize();

  const {
    pageNumber: eventPageNumber,
    pageSize: eventPageSize,
    setTotalDataCount: setEventTotalPagesCount,
    PageController: eventPageController,
  } = usePagination({ defaultPageSize: DEFAULT_PAGE_SIZE });

  const { data: eventsData, loading: eventLoading } = useListEventsQuery({
    variables: {
      extrinsicId: id,
      page: { number: eventPageNumber, size: eventPageSize },
      sessionKey,
    },
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      setEventTotalPagesCount(data.listEvents.count);
    },
  });

  const EVENTS_COLUMNS = EventColumn({ isMobile });

  return (
    <Skeleton.Provider isLoading={eventLoading}>
      <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
        <Skeleton.Loader className="w-[100px] h-5" containerClassName="h-5">
          <span className="text-white text-xl">
            Events{' '}
            <span className="text-offWhite text-base">({eventsData?.listEvents.count || 0})</span>
          </span>
        </Skeleton.Loader>
        <Table
          columns={EVENTS_COLUMNS}
          hasHeader
          data={!eventLoading ? eventsData?.listEvents.data : arrayOfSize(2)}
          classNames={{
            row: 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(100px,20%)_minmax(100px,30%)_minmax(100px,20%)_minmax(100px,20%)] border-0',
            columns: '!justify-start',
          }}
          isAccordion
        />
        {eventPageController}
      </Card>
    </Skeleton.Provider>
  );
}

export default ExtrinsicEventList;
