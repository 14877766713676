import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useTransfersListQuery } from 'gql';
import { usePagination } from 'hooks';
import { useParams } from 'react-router-dom';

import BalanceTransfer from './components/BalanceTransfer';

const useTransferList = () => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();

  const TRANSFERS_COLUMNS = BalanceTransfer();

  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: transferData, loading: isTransferLoading } = useTransfersListQuery({
    variables: { sessionKey, publicAddress: id!, page: { size: pageSize, number: pageNumber } },
    fetchPolicy: 'cache-and-network',
    skip: !id,
    onCompleted(data) {
      setTotalDataCount(data.transfersList!.count);
    },
  });

  return {
    PageController,
    pageSize,
    transferData,
    isTransferLoading,
    TRANSFERS_COLUMNS,
  };
};
export default useTransferList;
