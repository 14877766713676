import { Tooltip } from '@analog/ui/src';
import { Card, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { SortColumnsShard, arrayOfSize } from 'utils';

import useTransferList from './useTransferList';

const TransferList = () => {
  const {
    PageController,
    transferData,
    pageSize,
    isLoading,
    sortOrder,
    onSort,
    transferColumn,
    FilterController,
    totalTransfers,
  } = useTransferList();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8 ">
        {/* <Heading title="Transfers" /> */}
        <div className="flex gap-2">
          <SearchBox defaultSearchType={'All'} />
          {FilterController}
        </div>
      </div>
      <div className="mt-8 md:mt-[60px]">
        <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
          <Skeleton.Loader className="h-7 w-[100px]">
            <span className="text-white text-xl">
              Transfers{' '}
              <Skeleton.Loader className="h-7 w-[100px]">
                <span className="text-base">({totalTransfers})</span>
              </Skeleton.Loader>
            </span>
          </Skeleton.Loader>
          <Table
            columns={transferColumn}
            hasHeader
            data={!isLoading ? transferData : arrayOfSize(pageSize)}
            classNames={{
              row: 'grid gap-x-3 grid-cols-2 md:grid-cols-[minmax(130px,15%)_minmax(120px,15%)_minmax(140px,15%)_minmax(170px,25%)_minmax(170px,25%)_minmax(150px,5%)_minmax(70px,5%)]',
              columns: 'justify-start',
            }}
            onSort={onSort}
            sortOrder={sortOrder}
            sortOptions={SortColumnsShard}
            isLoading={isLoading}
          />
          {transferData?.length && PageController}
        </Card>
      </div>
      <Tooltip id={'hash'} />
    </>
  );
};

export default TransferList;
