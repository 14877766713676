import { HTMLAttributes } from 'types';

export const AnalogIcon = (props: HTMLAttributes<HTMLOrSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" fill="none" {...props}>
    <path
      fill="url(#a)"
      d="m31.09 6.745-9.917-5.74a7.512 7.512 0 0 0-7.504 0l-9.917 5.74A7.546 7.546 0 0 0 0 13.26V24.74a7.55 7.55 0 0 0 3.752 6.515l9.917 5.74A7.492 7.492 0 0 0 17.421 38a7.492 7.492 0 0 0 3.752-1.005l9.918-5.74a7.546 7.546 0 0 0 3.752-6.514V13.26a7.545 7.545 0 0 0-3.752-6.515Zm.443 17.996a4.219 4.219 0 0 1-2.098 3.64l-9.918 5.74a4.198 4.198 0 0 1-4.193 0l-9.918-5.74a4.217 4.217 0 0 1-2.097-3.64V13.26a4.22 4.22 0 0 1 2.097-3.641l9.918-5.74a4.184 4.184 0 0 1 4.192-.001l9.918 5.74a4.218 4.218 0 0 1 2.097 3.642V24.74h.002Z"
    />
    <path
      fill="url(#b)"
      d="m25.486 12.332-9.05 5.26c-.55.319-.841.904-.82 1.499-.001.028-.009.054-.009.083v7.264c0 .916.741 1.66 1.656 1.66.914 0 1.656-.742 1.656-1.66v-6.447l8.223-4.782a1.663 1.663 0 0 0 .606-2.268 1.653 1.653 0 0 0-2.262-.609Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={4.055}
        x2={17.463}
        y1={4.373}
        y2={18.974}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.125} stopColor="#D274F7" />
        <stop offset={1} stopColor="#9A74F7" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={4.877}
        x2={19.028}
        y1={1.596}
        y2={17.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.125} stopColor="#D274F7" />
        <stop offset={1} stopColor="#9A74F7" />
      </linearGradient>
    </defs>
  </svg>
);
