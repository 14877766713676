import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, useTransfersListQuery } from 'gql';
import { useFilter, usePagination, useSortBy } from 'hooks';

import TransferColumn from './components/TransferColumn';

function useTransferList() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber, setPageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { FilterController, selectedField } = useFilter(FilterType.Transfer, setPageNumber);

  const { data, loading: isLoading } = useTransfersListQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
      filter: selectedField,
    },
    onCompleted(data) {
      setTotalDataCount(data.transfersList!.count);
    },
    skip: selectedField.column ? !selectedField.value : false,
    fetchPolicy: 'cache-and-network',
  });

  const transferColumn = TransferColumn();

  return {
    PageController,
    transferData: data?.transfersList?.data,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
    transferColumn,
    FilterController,
  };
}

export default useTransferList;
