export const routes = {
  base: '/',
  blocks: '/blocks',
  block: '/block',
  blockId: ':idOrHash',
  extrinsics: '/extrinsics',
  extrinsic: '/extrinsic',
  extrinsicsId: ':extrinsicId',
  chronicles: '/chronicles',
  chronicle: '/chronicle',
  id: ':id',
  validators: '/validators',
  validator: '/validator',
  shards: '/shards',
  shard: '/shard',
  faqs: '/faqs',
  gmp: '/gmp',
  task: '/task',
  events: '/events',
  accounts: '/accounts',
  account: '/account',
  transfers: '/transfers',
  transfer: '/transfer',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  404: '/404',
};

export const detailsRoutes = {
  blockDetails: `${routes.block}/${routes.blockId}`,
  extrinsicsDetails: `${routes.extrinsic}/${routes.id}`,
  chroniclesDetails: `${routes.chronicle}/${routes.id}`,
  shardsDetails: `${routes.shard}/${routes.id}`,
  gmpDetails: `${routes.task}/${routes.id}`,
  validatorDetails: `${routes.validator}/${routes.id}`,
  accountDetails: `${routes.account}/${routes.id}`,
  transferDetails: `${routes.transfer}/${routes.id}`,
};
