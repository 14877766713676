import { Tooltip } from '@analog/ui/src';
import { Card, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { SortColumnsExtrinsics, arrayOfSize } from 'utils';

import { useExtrinsics } from './useExtrinsics';

const ExtrinsicsList = () => {
  const {
    extrinsicsData,
    PageController,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
    COLUMNS,
    FilterController,
  } = useExtrinsics();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        {/* <Heading
          title="Extrinsics"
          subTitle="Examine all the details about the extrinsics, including extrinsic id, block time, hash, etc."
        /> */}
        <div className="flex gap-2">
          <SearchBox defaultSearchType={'All'} />
          {FilterController}
        </div>
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <Card extraClasses="!rounded-[20px] !p-6 !pb-5 flex flex-col gap-[18px]">
          <span className="text-white text-xl">Extrinsics</span>
          <Table
            columns={COLUMNS}
            hasHeader
            classNames={{
              row: 'grid gap-x-3 grid-cols-2 md:grid-cols-[minmax(130px,20%)_minmax(90px,15%)_minmax(150px,20%)_minmax(100px,15%)_minmax(70px,10%)_minmax(210px,10%)_minmax(15px,2%)] border-none',
              columns: 'justify-start',
            }}
            data={!isLoading ? extrinsicsData : arrayOfSize(pageSize)}
            sortOptions={SortColumnsExtrinsics}
            sortOrder={sortOrder}
            onSort={onSort}
            isAccordion
            isLoading={isLoading}
            uniqueKey="id"
          />
          <Skeleton.Provider isLoading={isLoading}>
            {(isLoading || extrinsicsData?.length) && PageController}
          </Skeleton.Provider>
        </Card>
      </div>
      <Tooltip id={'hash'} />
    </>
  );
};

export default ExtrinsicsList;
