import React, { useState } from 'react';

import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { ArcElement, Chart as ChartJS, Legend, Tooltip, TooltipItem } from 'chart.js';
import { Card } from 'components';
import { Skeleton } from 'components/Skeleton';
import { TelemetryQuery } from 'gql';
import { Doughnut } from 'react-chartjs-2';
import { getIcon } from 'utils';

import useTelemetry, { NetworkStatsType } from './useTelemetry';

const NetworkStats: { icon?: JSX.Element; title: string; key: keyof NetworkStatsType }[] = [
  {
    icon: (
      <Icon
        icon={allImgPaths.blockIcon}
        iconClasses="animate-spin-slow h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Finalized Blocks',
    key: 'finalized_blocks',
  },
  // {
  //   icon: (
  //     <Icon
  //       icon={allImgPaths.stakeIcon}
  //       iconClasses="h-6 w-6"
  //       extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
  //     />
  //   ),
  //   title: 'Staked / Bonded',
  //   key: 'active_members',
  // },
  {
    icon: (
      <Icon
        icon={allImgPaths.transferIcon}
        iconClasses="rotate-90 h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Transfers',
    key: 'transfers',
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.signIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Extrinsics',
    key: 'extrinsics',
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.holderIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Total Accounts',
    key: 'total_accounts',
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.stakeIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Active Chronicles',
    key: 'active_members',
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.inflationIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Shards Live',
    key: 'shards_live',
  },
  {
    icon: (
      <Icon
        icon={allImgPaths.parametersIcon}
        iconClasses="h-6 w-6"
        extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
      />
    ),
    title: 'Tasks Created',
    key: 'tasks_created',
  },
  // {
  //   icon: (
  //     <Icon
  //       icon={allImgPaths.inflationIcon}
  //       iconClasses="h-6 w-6"
  //       extraClasses="bg-transparent h-auto min-w-8 flex-none justify-start"
  //     />
  //   ),
  //   title: 'Inflation Rate',
  //   key: 'tasks_created',
  // },
];
export const AnalogNetworkStats = (stats: NetworkStatsType) => {
  const [hoverPositions, setHoverPositions] = useState<{ x: number; y: number }[]>(
    Array.from({ length: NetworkStats.length }, () => ({ x: 0, y: 0 }))
  );
  const [isHovering, setIsHovering] = useState<boolean[]>(
    Array.from({ length: NetworkStats.length }, () => false)
  );

  const handleMouseMove = (index: number, e: React.MouseEvent<HTMLDivElement>) => {
    const containerRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - containerRect.left;
    const y = e.clientY - containerRect.top;

    const newPositions = [...hoverPositions];
    newPositions[index] = { x, y };
    setHoverPositions(newPositions);
  };

  const handleMouseEnter = (index: number) => {
    const newHoverState = [...isHovering];
    newHoverState[index] = true;
    setIsHovering(newHoverState);
  };

  const handleMouseLeave = (index: number) => {
    const newHoverState = [...isHovering];
    newHoverState[index] = false;
    setIsHovering(newHoverState);
  };
  // Analog Network Stats
  return (
    <div className="flex gap-4 flex-col">
      <span className="md:text-2xl text-xl text-white">Chain Data</span>
      <div className="gap-4 lg:gap-6 justify-between grid grid-cols-2  xl:grid-cols-5 grid-flow-row [&>*:nth-child(5)]:col-span-2 xl:[&>*:nth-child(5)]:col-span-1">
        {NetworkStats.map(({ icon, title, key }, index) => (
          <Card key={index} hoverEffect extraClasses="overflow-hidden h-max">
            <div
              className="flex flex-col h-full relative"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              onMouseMove={(e) => handleMouseMove(index, e)}
            >
              <div
                className="absolute h-[70px] w-[70px] rounded-[38px] bg-[#ff00f566] bottom-7 right-5 blur-[25px] hidden group-hover:flex z-[-1]"
                style={{ top: hoverPositions[index].y - 25, left: hoverPositions[index].x - 25 }}
              ></div>
              <div>
                <p className="text-white text-sm lg:text-base whitespace-nowrap flex items-center gap-2">
                  {icon}
                  <span>{title}</span>
                </p>
                <div className="flex gap-2 flex-col h-full">
                  <div className="flex items-end flex-wrap">
                    <Skeleton.Loader className="w-20 h-10" containerClassName="h-10">
                      <div className="relative overflow-hidden">
                        <div
                          className="absolute animate-slide-in text-[32px] lg:text-[40px] text-white font-medium leading-9 lg:leading-[56px]"
                          key={`title-${new Number(stats[key]).toLocaleString()}`}
                        >
                          {new Number(stats[key]).toLocaleString()}
                        </div>
                        <div
                          className="animate-fade-out text-[32px] lg:text-[40px] text-white font-medium leading-9 lg:leading-[56px]"
                          key={`title-prev-${new Number(stats[key]).toLocaleString()}`}
                        >
                          {new Number(stats[key]).toLocaleString()}
                        </div>
                      </div>
                    </Skeleton.Loader>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
export const ChainsSupported = ({ chains }: { chains: string[] }) => {
  return (
    <div className="flex gap-4 flex-col">
      <Skeleton.Loader className="w-[10%] h-8">
        <span className="md:text-2xl text-xl text-white">Chains Supported</span>
      </Skeleton.Loader>
      <div className="gap-4 lg:gap-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        {chains.map((chain, index) => (
          <Card extraClasses="!py-[14px] !pl-4 rounded-2xl" hoverEffect key={index}>
            <div className="flex gap-[9px]">
              <Skeleton.Loader className="w-10 h-10" containerClassName="h-10">
                <img src={getIcon(chain)} alt={chain} className="w-10 h-10" />
              </Skeleton.Loader>
              <div className="flex flex-col">
                <Skeleton.Loader className="w-[70px] h-3" containerClassName="h-3">
                  <span className="text-offWhite text-xs">Chain</span>
                </Skeleton.Loader>
                <Skeleton.Loader className="w-[100px] h-5 mt-2" containerClassName="h-5">
                  <span className="text-white capitalize">{chain}</span>
                </Skeleton.Loader>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export const NetworkStatsUI = (stats: NetworkStatsType) => {
  return (
    <div className="flex flex-col gap-4">
      <span className="md:text-2xl text-xl text-white">Chain Data</span>
      <div className="gap-4 md:gap-x-9 justify-between grid grid-cols-2 bg-[#010101] rounded-2xl p-4 sm:py-[21px] sm:px-5 border border-[#2B2B2B]">
        {NetworkStats.map(({ icon, title, key }) => (
          <div className="flex flex-col h-full">
            <p className="text-white/70 text-sm whitespace-nowrap flex items-start">
              {icon}
              <span className="whitespace-pre-wrap">{title}</span>
            </p>
            <Skeleton.Loader className="w-20 h-10" containerClassName="h-10">
              <div className="relative overflow-hidden pl-8">
                <div
                  className="absolute animate-slide-in text-sm text-white font-medium"
                  key={`title-${new Number(stats[key]).toLocaleString()}`}
                >
                  {new Number(stats[key]).toLocaleString()}
                </div>
                <div
                  className="animate-fade-out text-sm text-white font-medium"
                  key={`title-prev-${new Number(stats[key]).toLocaleString()}`}
                >
                  {new Number(stats[key]).toLocaleString()}
                </div>
              </div>
            </Skeleton.Loader>
          </div>
        ))}
      </div>
    </div>
  );
};

// Register required components
ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = () => {
  const data = {
    labels: ['Circulating', 'Staking', 'Treasury', 'Others'],
    datasets: [
      {
        data: [657.432, 819.742, 13.927, 50.243], // Values in Millions
        backgroundColor: ['#F0BAFF', '#C763FF', '#6F4480', '#A877B3'],
        borderWidth: 0,
      },
    ],
  };

  const totalValue = data.datasets[0].data.reduce((acc, value) => acc + value, 0);

  const options = {
    maintainAspectRatio: false,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: TooltipItem<'doughnut'>) => {
            const value = tooltipItem.raw as number;
            const percentage = ((value / totalValue) * 100).toFixed(2);
            const label = data.labels[tooltipItem.dataIndex];
            return `${label}: ${percentage}%`;
          },
        },
      },
    },
  };

  return (
    <div className="flex flex-col gap-4">
      <span className="md:text-2xl text-xl text-white">Token Distribution</span>
      <div className="h-[-webkit-fill-available] border border-[#2B2B2B] rounded-xl bg-[#010101] shadow-md">
        <div className="flex justify-between py-2 px-5 bg-white/10 rounded-t-xl">
          <p className="text-white text-xs font-medium">Total Issuance:</p>
          <p className="text-white text-xs">1.541B</p>
        </div>
        <div className="flex items-center justify-between gap-2 sm:gap-6 p-4">
          <div className="w-[80px] sm:w-[160px] h-[80px] sm:h-[130px] sm:pl-[30px]">
            <Doughnut data={data} options={options} />
          </div>

          <div className="space-y-3 text-white pr-[20px] sm:pr-[119px]">
            <ul className="space-y-1">
              <li className="flex items-center text-sm sm:whitespace-nowrap">
                <span className="w-2 h-5 bg-[#F0BAFF] rounded-[2px] mr-2"></span>
                Circulating ·<span className="text-white/70">657.432M</span>
              </li>
              <li className="flex items-center text-sm sm:whitespace-nowra">
                <span className="w-2 h-5 bg-[#C763FF] rounded-[2px] mr-2"></span>
                Staking ·<span className="text-white/70">819.742M</span>
              </li>
              <li className="flex items-center text-sm sm:whitespace-nowra">
                <span className="w-2 h-5 bg-[#6F4480] rounded-[2px] mr-2"></span>
                Treasury ·<span className="text-white/70">13.927M</span>
              </li>
              <li className="flex items-center text-sm sm:whitespace-nowra">
                <span className="w-2 h-5 bg-[#A877B3] rounded-[2px] mr-2"></span>
                Others ·<span className="text-white/70">50.243M</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const Telemetry = ({
  telemetryData,
  isTelemetryLoading,
  supportedChain,
  isSupportedChainLoading,
}: {
  telemetryData: TelemetryQuery | undefined;
  isTelemetryLoading?: boolean;
  supportedChain?: string[];
  isSupportedChainLoading?: boolean;
}) => {
  const { networkStats, chains } = useTelemetry({ telemetryData, supportedChain });
  return (
    <>
      <Skeleton.Provider isLoading={isTelemetryLoading}>
        {/* <AnalogNetworkStats {...networkStats} /> */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <NetworkStatsUI {...networkStats} />
          {/* <DonutChart /> */}
        </div>
      </Skeleton.Provider>

      <Skeleton.Provider isLoading={isSupportedChainLoading}>
        <ChainsSupported
          chains={!isSupportedChainLoading ? (supportedChain?.length ? chains : []) : ['']}
        />
      </Skeleton.Provider>
    </>
  );
};

export default Telemetry;
