import { AnalogIcon, Identicon } from 'components';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { formatAmount } from 'utils';

const AccountColumn = () => [
  {
    title: 'Account',
    key: 'publicAddress',
    render(data: any) {
      return (
        <Link
          className="flex gap-[10px] col-span-2 md:col-span-1 cursor-pointer items-center"
          to={`${routes.account}/${data[data.columnKey]}`}
          state={{ data: data }}
        >
          <Identicon value={data[data.columnKey]} size={24} />
          <div className={'flex flex-col overflow-hidden w-full'}>
            <TableColumns
              value={data[data.columnKey]}
              withTruncate
              withCopyButton
              textColor={FontColors.PURPLE}
            />
          </div>
        </Link>
      );
    },
  },
  {
    title: 'Extrinsics',
    key: 'extrinsic_count',
    render: (data: any) => {
      return (
        <div className="flex flex-row items-center gap-1">
          <TableColumns
            value={data[data.columnKey]}
            classNames={{ beforeIcon: 'h-[9px] w-[9px]', content: 'capitalize text-white' }}
          />
        </div>
      );
    },
  },
  {
    title: 'Balance',
    key: 'free',
    render: (data: any) => (
      <div className="flex flex-row items-center gap-1">
        <AnalogIcon className="w-5 h-5" />
        <TableColumns
          value={formatAmount(data[data.columnKey])}
          classNames={{ beforeIcon: 'h-[9px] w-[9px]', content: 'capitalize text-white' }}
        />
      </div>
    ),
  },
];

export default AccountColumn;
