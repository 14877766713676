import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Card, Table } from 'components';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { arrayOfSize } from 'utils';

import useLatestBlock from './useLatestBlock';

export function LatestBlocks() {
  const { COLUMNS, blocksListLoading, blocksList } = useLatestBlock();

  return (
    <Card>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between">
          <span className="text-white text-xl flex items-center">
            <Icon
              icon={allImgPaths.blockIcon}
              iconClasses="animate-spin-slow"
              extraClasses="bg-transparent"
            />
            Latest Blocks
          </span>
          <Link className="flex flex-row gap-[5px] items-center" to={routes.blocks}>
            {blocksList?.listBlocks.count && (
              <>
                <span className="text-white whitespace-nowrap cursor-pointer">View All</span>
                <img
                  src={allImgPaths.rightArrowIcon}
                  alt="right-arrow"
                  className="h-[14px] w-[14px] md:h-4 md:w-4 mt-[2.85px]"
                />
              </>
            )}
          </Link>
        </div>
        <div>
          <Table
            columns={COLUMNS}
            animation
            classNames={{
              row: 'md:grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(340px,75%)_minmax(160px,25%)] !py-4 flex',
              bodyBase: 'h-[360px] overflow-hidden',
              bodyWrapper:
                'flex flex-col-reverse min-h-[360px] h-[360px] max-h-[360px] overflow-auto',
            }}
            data={
              !blocksListLoading
                ? [...(blocksList?.listBlocks.data || [])].reverse()
                : arrayOfSize(10)
            }
            isHeaderHidden
            bodyId="blocks"
            isLoading={blocksListLoading}
          />
        </div>
      </div>
    </Card>
  );
}
