import { Card, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { arrayOfSize } from 'utils';

import useAccountDetails from '../useAccountDetails';

import Switches from './Switches';

const AccountRelationData = () => {
  const { tableData, selectedTab, setSelectedTab } = useAccountDetails();
  return (
    <Skeleton.Provider isLoading={tableData[selectedTab].isLoading}>
      <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
        <div className="flex justify-between items-center flex-col-reverse md:flex-row gap-[15px]">
          <Skeleton.Loader className="w-[100px] h-5" containerClassName="h-5">
            <span className="text-white text-xl">
              <div className="capitalize">
                {selectedTab}
                <span className="text-offWhite"> {tableData[selectedTab].count || 0}</span>
              </div>
            </span>
          </Skeleton.Loader>
          <Skeleton.Loader className="md:w-[200px] w-full h-10" containerClassName="h-10">
            <Switches
              counts={{
                transferCount: tableData.transfers.count || '',
                extrinsicsCount: tableData.extrinsics.count || '',
              }}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </Skeleton.Loader>
        </div>
        <Table
          columns={tableData[selectedTab].columns}
          hasHeader
          data={
            !tableData[selectedTab].isLoading ? tableData[selectedTab].data?.data : arrayOfSize(2)
          }
          classNames={{
            row:
              selectedTab === 'extrinsics'
                ? 'grid gap-x-3 grid-cols-2 border-none md:grid-cols-[minmax(120px,20%)_minmax(120px,25%)_minmax(130px,15%)_minmax(130px,15%)_minmax(225px,25%)_minmax(15px,3%)]'
                : 'grid gap-x-3 grid-cols-2 border-none md:grid-cols-[minmax(130px,10%)_minmax(120px,10%)_minmax(140px,15%)_minmax(170px,25%)_minmax(170px,25%)_minmax(150px,10%)_minmax(70px,10%)]',
            columns: '!justify-start',
          }}
          isAccordion={selectedTab === 'extrinsics'}
          isLoading={tableData[selectedTab].isLoading}
        />
        {tableData[selectedTab].PageController}
      </Card>
    </Skeleton.Provider>
  );
};

export default AccountRelationData;
