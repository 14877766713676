import { HashIcon, Icon } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { AnalogIcon } from 'components';
import { AccountDetailsQuery } from 'gql';
import { formatAmount } from 'utils';

interface AccountDataProps {
  data: AccountDetailsQuery | undefined;
}

const AccountData = ({ data }: AccountDataProps) => {
  return [
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Wallet Address',
      value: (
        <HashIcon value={data?.accountDetails?.publicAddress} className="text-white" copyable />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Public Key',
      value: <HashIcon value={data?.accountDetails?.address} className="text-white" copyable />,
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Balance',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-6 h-6" />
          {formatAmount(data?.accountDetails?.data?.data?.free)}
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Reserved',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-6 h-6" />
          {formatAmount(data?.accountDetails.data.data.reserved)}
        </div>
      ),
      enable: data?.accountDetails.data.data.reserved !== '0',
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Frozen',
      value: (
        <div className="flex flex-row items-center gap-1">
          <AnalogIcon className="w-6 h-6" />
          {formatAmount(data?.accountDetails.data.data.frozen)}
        </div>
      ),
      enable: data?.accountDetails.data.data.frozen !== '0',
    },
    {
      icon: <Icon icon={allImgPaths.clipboardCloseIcon} />,
      label: 'Nonce',
      value: data?.accountDetails.data.nonce,
    },
  ];
};
export default AccountData;
