import allImgPaths from 'assets/allImgPaths';
import { routes } from 'routes/routesConst';

export const NavBarOptions = [
  { title: 'Home', path: routes.base },
  { title: 'Blocks', path: routes.blocks },
  { title: 'Extrinsics', path: routes.extrinsics },
  { title: 'Transfers', path: routes.transfers },
  { title: 'Chronicles', path: routes.chronicles },
  { title: 'GMP', path: routes.gmp },
  { title: 'Validators', path: routes.validators },
  { title: 'Shards', path: routes.shards },
  { title: 'Accounts', path: routes.accounts },
];

export const socialHandlers = [
  {
    img: allImgPaths.discordIcon,
    link: 'https://discord.com/invite/analog',
  },
  {
    img: allImgPaths.telegramIcon,
    link: 'https://t.me/analogtimer',
  },
  {
    img: allImgPaths.xIcon,
    link: 'https://twitter.com/OneAnalog',
  },
  {
    img: allImgPaths.mediumIcon,
    link: 'https://medium.com/@analogtime',
  },
];
