import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Card, Table } from 'components';
import { routes } from 'routes/routesConst';
import { arrayOfSize } from 'utils';

import useLatestTransfer from './useLatestTransfer';

export const LatestTransfer = () => {
  const { Navigate, transferListLoading, COLUMNS, transferList } = useLatestTransfer();
  return (
    <Card>
      <div className="flex flex-col gap-5">
        <div className="flex justify-between">
          <span className="text-white text-xl flex items-center">
            <Icon
              icon={allImgPaths.transferIcon}
              iconClasses="rotate-90"
              extraClasses="bg-transparent"
            />
            Transfers
          </span>
          {transferList?.transfersList?.data?.length && (
            <div
              className="flex flex-row gap-[5px] items-center"
              onClick={() => Navigate(routes.transfers)}
            >
              <span className="text-white whitespace-nowrap cursor-pointer">View All</span>
              <img
                src={allImgPaths.rightArrowIcon}
                alt="right-arrow"
                className="h-[14px] w-[14px] md:h-4 md:w-4 mt-[2.85px]"
              />
            </div>
          )}
        </div>
        {/* [minmax(110px,25%)_minmax(70px,25%)_minmax(90px,10%)_minmax(120px,50%)] */}
        <div>
          <Table
            columns={COLUMNS}
            animation
            classNames={{
              row: '!py-4 flex justify-between',
              bodyBase: 'h-[360px] overflow-hidden',
              bodyWrapper:
                'flex flex-col-reverse min-h-[360px] h-[360px] max-h-[360px] overflow-auto',
              transfer: 'w-fit',
            }}
            data={
              !transferListLoading
                ? [...(transferList?.transfersList?.data || [])].reverse()
                : arrayOfSize(10)
            }
            isHeaderHidden
            bodyId="extrinsics"
            isLoading={transferListLoading}
          />
        </div>
      </div>
    </Card>
  );
};
