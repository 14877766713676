import { Tooltip } from '@analog/ui/src';
import { Card, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { SortColumnsValidators, arrayOfSize } from 'utils';

import ValidatorColumn from './components/ValidatorColumns';
import { useValidatorList } from './useValidatorList';

const VALIDATOR_COLUMNS = ValidatorColumn();

const ValidatorList = () => {
  const { PageController, isLoading, validatorsData, pageSize, onSort } = useValidatorList();

  return (
    <Skeleton.Provider isLoading={isLoading}>
      <div className="flex flex-col gap-4 md:gap-8">
        {/* <Heading
          title="Validators"
          subTitle="Explore a list of validators currently securing the Analog Network."
          classNames={{ title: 'font-normal' }}
        /> */}
        <SearchBox defaultSearchType={'All'} />
      </div>
      <div className="mt-8 md:mt-[60px]">
        <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
          <span className="text-white text-xl">Validator</span>
          <Table
            isLoading={isLoading}
            columns={VALIDATOR_COLUMNS}
            hasHeader
            data={isLoading ? arrayOfSize(pageSize) : validatorsData}
            classNames={{
              row: 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(220px,22%)_minmax(100px,10%)_minmax(90px,10%)_minmax(100px,8%)_minmax(120px,8%)_minmax(100px,8%)_minmax(90px,8%)_minmax(120px,7%)_minmax(100px,7%)_minmax(100px,7%)_minmax(120px,8%)]',
              columns: 'justify-start',
            }}
            sortOptions={SortColumnsValidators}
            onSort={onSort}
          />
          {(isLoading || validatorsData?.length) && PageController}
        </Card>
      </div>
      <Tooltip id={'hash'} />
    </Skeleton.Provider>
  );
};

export default ValidatorList;
