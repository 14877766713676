import { DetailsList } from '@analog/ui/src';
import { ResourceNotFound, SearchBox } from 'components';
import Heading from 'components/common/Heading';
import { Skeleton } from 'components/Skeleton';

import AccountRelationData from './components/AccountRelationData';
import useAccountDetails from './useAccountDetails';

function AccountDetails() {
  const { id, accountData, isAccountLoading, error } = useAccountDetails();

  // const rowClasses = {
  //   extrinsics:
  //     'md:grid-cols-[minmax(90px,20%)_minmax(120px,25%)_minmax(130px,15%)_minmax(130px,15%)_minmax(130px,25%)]',
  //   balance:
  //     'md:grid-cols-[minmax(120px,10%)_minmax(130px,10%)_minmax(150px,25%)_minmax(150px,25%)_minmax(140px,15%)_minmax(130px,15%)]',
  // };

  if (error) {
    return <ResourceNotFound page={'accounts'} searchType={'Accounts'} />;
  }

  return (
    <Skeleton.Provider isLoading={isAccountLoading}>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          className="text-white font-medium leading-none truncate w-full"
          title={`Account`}
          subTitle={`#${id}`}
          isTruncate
        />
        <SearchBox defaultSearchType={'Accounts'} />
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <DetailsList title="Account Info" details={accountData} isLoading={isAccountLoading} />
        <AccountRelationData />
        {/* <Card extraClasses="flex gap-4 md:gap-[22px] flex-col mt-[32px]">
          <span className="text-white text-xl flex gap-4">
            <div>
              Transfers
              <span className="text-[#A6A3AC]"> {tableData.balance.count || 0}</span>
            </div>
          </span>

          <Table
            columns={tableData.balance.columns}
            classNames={{
              row: `grid gap-x-3 grid-cols-2 border-none ${rowClasses.balance}`,
              columns: 'justify-start',
            }}
            data={
              !tableData.balance.isLoading
                ? tableData.balance.data?.data
                : arrayOfSize(tableData.balance.pageSize)
            }
            isLoading={tableData.balance.isLoading}
          />
          {tableData.balance.count && tableData.balance.PageController}
        </Card> */}
        {/* <Card extraClasses="flex gap-4 md:gap-[22px] flex-col mt-[32px]">
          <span className="text-white text-xl flex gap-4">
            <div>
              Extrinsics
              <span className="text-[#A6A3AC]"> {tableData.extrinsics.count || 0}</span>
            </div>
          </span>

          <Table
            columns={tableData.extrinsics.columns}
            classNames={{
              row: `grid gap-x-3 grid-cols-2 border-none ${rowClasses.extrinsics}`,
              columns: 'justify-start',
            }}
            data={
              !tableData.extrinsics.isLoading
                ? tableData.extrinsics.data?.data
                : arrayOfSize(tableData.extrinsics.pageSize)
            }
            sortOptions={SortColumnsExtrinsics}
            sortOrder={sortOrder}
            onSort={onSort}
            isLoading={tableData.extrinsics.isLoading}
          />
          {tableData.extrinsics.count && tableData.extrinsics.PageController}
        </Card> */}
      </div>
    </Skeleton.Provider>
  );
}

export default AccountDetails;
