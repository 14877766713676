import { Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { classes, statusColor, timeAgo } from 'utils';

const LatestBlockColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'Block',
      key: 'number',
      shouldWrap: false,

      render: (data: ColumnData) => (
        <div className="flex gap-[10px] col-span-1">
          <div className={'flex flex-col overflow-hidden'}>
            <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
              <Text
                extraClass="text-pink text-sm cursor-pointer w-fit"
                onClick={() => {
                  navigate(`${routes.block}/${data[data.columnKey]}`);
                }}
              >
                #{data[data.columnKey]}
              </Text>
            </Skeleton.Loader>
            {/* <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
              <Text extraClass="text-xs text-offWhite">
                {data?.created_at && timeAgo(new Date(data?.created_at))}
              </Text>
            </Skeleton.Loader> */}
          </div>
        </div>
      ),
    },

    {
      title: 'Status',
      key: 'status',
      render: (data: ColumnData) => (
        <div
          className={classes(
            `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
            statusColor(data[data.columnKey])
          )}
        >
          <span
            className={
              'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
            }
          >
            {data[data.columnKey] || '-'}
          </span>
        </div>
      ),
    },

    {
      title: 'Extrinsics',
      key: 'extrinsicsCount',

      render: (data: ColumnData) => (
        <div className={'flex flex-col overflow-hidden'}>
          <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
            <Text extraClass="text-white text-sm">{data[data.columnKey]}</Text>
          </Skeleton.Loader>
          <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
            <Text extraClass="text-xs text-offWhite">Extrinsics</Text>
          </Skeleton.Loader>
        </div>
      ),
    },
    {
      title: 'Age',
      key: 'created_at',
      render: (data: ColumnData) => (
        <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
          <Text extraClass="text-xs text-offWhite">
            {data[data.columnKey] && timeAgo(new Date(data[data.columnKey]))}
          </Text>
        </Skeleton.Loader>
      ),
    },
    // {
    //   title: 'Author',
    //   key: 'author',

    //   render: (data: ColumnData) => (
    //     <div className="flex lg:block flex-col">
    //       <div className="flex gap-1 items-end">
    //         <Skeleton.Loader className="w-[50px]" containerClassName="h-5">
    //           <span className="text-white text-sm line-clamp-1">
    //             {truncate(data[data.columnKey])}
    //           </span>
    //         </Skeleton.Loader>
    //       </div>
    //       <Skeleton.Loader className="w-[50px]" containerClassName="h-5">
    //         <span className="text-xs text-offWhite">Block Author</span>
    //       </Skeleton.Loader>
    //     </div>
    //   ),
    // },
  ];
};

export default LatestBlockColumn;
