import { useRef, useState } from 'react';

import allImgPaths from 'assets/allImgPaths';
import { FilterQueryResult } from 'gql';
import { useOutsideClick } from 'hooks';
import { parseJson } from 'utils';

export type FilterField = 'status' | 'action' | 'network' | 'state';

type FilterProps = {
  filterField: Array<FilterField>;
  filterValue: Array<string>;
  handleApply: (obj: { column: string; value: string }) => void;
  setFilterValue: React.Dispatch<React.SetStateAction<string[]>>;
  data: FilterQueryResult['data'];
  filterType: string;
};

const Filter = ({
  filterField,
  filterValue,
  handleApply,
  setFilterValue,
  data,
  filterType,
}: FilterProps) => {
  const impactRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('field');
  const [selectedField, setSelectedField] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<string>('');

  const filterTitle =
    filterType === 'members' ? 'Chronicles' : filterType === 'tasks' ? 'GMP' : filterType;

  useOutsideClick(impactRef, () => close());
  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  const isFilter = selectedField && selectedValue;
  return (
    <div ref={impactRef} className="relative">
      <button onClick={open}>
        <img
          src={isFilter ? allImgPaths.filterOnIcon : allImgPaths.filterIcon}
          className=" w-10 h-10 md:w-12 md:h-12 pointer-events-none"
          alt="search"
        />
      </button>

      {isOpen && (
        <div className="origin-top-right absolute left-auto right-0 z-50 mt-2 bg-[#141414] w-[300px] rounded-xl p-5">
          <div className="mb-[14px] flex justify-between">
            <h3 className="text-base font-medium tracking-wide text-white capitalize">
              Filter {filterTitle}
            </h3>
            <button
              className="text-pink"
              onClick={() => {
                setSelectedTab('field');
                setSelectedField('');
                setSelectedValue('');
                handleApply({ column: '', value: '' });
              }}
            >
              Clear
            </button>
          </div>
          <div className="mb-[14px] w-full flex justify-between rounded-3xl border border-white/20 p-1">
            <button
              className={`text-white text-sm py-2 px-10 ${
                selectedTab === 'field' ? 'bg-white/[0.06] rounded-full' : ''
              }`}
              onClick={() => setSelectedTab('field')}
            >
              Field
            </button>
            <button
              className={`text-white text-sm py-2 px-10 disabled:cursor-not-allowed ${
                selectedTab === 'value' ? 'bg-white/[0.06] rounded-full' : ''
              }`}
              disabled={!selectedField}
              onClick={() => setSelectedTab('value')}
            >
              Value
            </button>
          </div>

          <div className="flex flex-col gap-3 p-4 rounded-lg bg-white/[0.02] mb-[14px] max-h-[182px] overflow-x-hidden overflow-y-auto border border-[#2b2b2b]">
            {selectedTab === 'field' &&
              filterField.map((option) => {
                return (
                  <button
                    key={option}
                    className=""
                    onClick={() => {
                      setFilterValue(
                        (data?.filter[option.toLowerCase() as FilterField] as string[]) || []
                      );
                      setSelectedField(option);
                      setSelectedTab('value');
                    }}
                  >
                    <div className="text-white capitalize text-sm flex items-center gap-2.5 leading-[20px]">
                      {selectedField === option ? (
                        <div className="rounded-full border-[6px] border-[#E563F9]">
                          <div className="p-1"></div>
                        </div>
                      ) : (
                        <div className="rounded-full border-[10px] border-white/[0.04]">
                          <div className="p-0"></div>
                        </div>
                      )}
                      {option}
                    </div>
                  </button>
                );
              })}
            {selectedTab === 'value' &&
              filterValue?.map((option) => {
                const parsedValue = selectedField === 'network' ? parseJson(option) : null;
                const value =
                  parsedValue && Array.isArray(parsedValue) ? parsedValue.join(',') : option;
                return (
                  <button
                    key={option}
                    className=""
                    onClick={() => {
                      setSelectedValue(option);
                      handleApply({ column: selectedField, value: option });
                    }}
                  >
                    <div className="text-white capitalize text-sm flex items-center gap-2.5 leading-[20px]">
                      {selectedValue === option ? (
                        <div className="rounded-full border-[6px] border-[#E563F9]">
                          <div className="p-1"></div>
                        </div>
                      ) : (
                        <div className="rounded-full border-[10px] border-white/[0.04]">
                          <div className="p-0"></div>
                        </div>
                      )}
                      {value}
                    </div>
                  </button>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
