import { Dispatch, SetStateAction } from 'react';

interface SwitchesProps {
  selectedTab: 'transfers' | 'extrinsics';
  setSelectedTab: Dispatch<SetStateAction<'transfers' | 'extrinsics'>>;
  counts: { transferCount: number | string; extrinsicsCount: number | string };
}

const Switches = ({ selectedTab, setSelectedTab, counts }: SwitchesProps) => {
  return (
    <>
      <label className="shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-[25px] p-1 border-[1px] border-solid border-[#FFFFFF33] w-full md:w-auto">
        {/* <input type="checkbox" className="sr-only" checked={isChecked} onChange={onChange} /> */}
        <span
          className={`flex items-center space-x-[6px] rounded-[32px] py-[5px] px-4 text-sm font-medium text-white w-full md:w-auto justify-center ${
            selectedTab === 'transfers' ? 'text-primary bg-[#1B1B1B]' : ''
          }`}
          onClick={() => {
            setSelectedTab('transfers');
          }}
        >
          Transfers&nbsp;
        </span>
        <span
          className={`flex items-center space-x-[6px] rounded-[32px] py-[5px] px-4 text-sm font-medium text-white w-full md:w-auto justify-center ${
            selectedTab === 'extrinsics' ? 'text-primary bg-[#1B1B1B]' : ''
          }`}
          onClick={() => {
            setSelectedTab('extrinsics');
          }}
        >
          Extrinsics&nbsp;
        </span>
      </label>
    </>
  );
};

export default Switches;
