import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { timeAgo } from 'utils';

const LatestBlockColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'Block',
      key: 'number',
      shouldWrap: false,

      render: (data: ColumnData) => {
        return (
          <div className="flex gap-[10px] col-span-1">
            <div className={'flex flex-col overflow-hidden'}>
              <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
                <p className="text-sm">
                  Block#:{'  '}
                  <Text
                    extraClass="text-pink text-base cursor-pointer w-fit"
                    onClick={() => {
                      navigate(`${routes.block}/${data[data.columnKey]}`);
                    }}
                  >
                    {data[data.columnKey]}
                  </Text>
                </p>
              </Skeleton.Loader>
              <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
                <p className="text-sm flex gap-1 text-[#9D9D9D]">
                  Includes:{'  '}
                  <div className="flex gap-1.5 items-center">
                    <Text
                      extraClass="text-pink text-sm cursor-pointer"
                      onClick={() => navigate(`${routes.block}/${data[data.columnKey]}`)}
                    >
                      {data.extrinsicsCount} Extrinsics
                    </Text>
                    <span className="text-pink flex items-start">.</span>
                    <Text
                      extraClass="text-pink text-sm cursor-pointer"
                      onClick={() => navigate(`${routes.block}/${data[data.columnKey]}`)}
                    >
                      {data.eventsCount} Events
                    </Text>
                  </div>
                </p>
              </Skeleton.Loader>
            </div>
          </div>
        );
      },
    },

    // {
    //   title: 'Status',
    //   key: 'status',
    //   render: (data: ColumnData) => (
    //     <div
    //       className={classes(
    //         `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
    //         statusColor(data[data.columnKey])
    //       )}
    //     >
    //       <span
    //         className={
    //           'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
    //         }
    //       >
    //         {data[data.columnKey] || '-'}
    //       </span>
    //     </div>
    //   ),
    // },

    // {
    //   title: 'Extrinsics',
    //   key: 'extrinsicsCount',

    //   render: (data: ColumnData) => (
    //     <div className={'flex flex-col overflow-hidden'}>
    //       <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
    //         <Text extraClass="text-white text-sm">{data[data.columnKey]}</Text>
    //       </Skeleton.Loader>
    //       <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
    //         <Text extraClass="text-xs text-offWhite">Extrinsics</Text>
    //       </Skeleton.Loader>
    //     </div>
    //   ),
    // },
    {
      title: 'Age',
      key: 'created_at',
      render: (data: ColumnData) => (
        <div className={'flex flex-col overflow-hidden'}>
          <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
            <div className="flex items-center justify-end">
              <Text extraClass="text-sm text-offWhite text-nowrap" ellipsis={false}>
                {data[data.columnKey] && timeAgo(new Date(data[data.columnKey]))}
              </Text>
              <Icon icon={allImgPaths.greenTickCircleIcon} extraClasses="bg-transparent h-0" />
            </div>
          </Skeleton.Loader>
        </div>
      ),
    },
    // {
    //   title: 'Author',
    //   key: 'author',

    //   render: (data: ColumnData) => (
    //     <div className="flex lg:block flex-col">
    //       <div className="flex gap-1 items-end">
    //         <Skeleton.Loader className="w-[50px]" containerClassName="h-5">
    //           <span className="text-white text-sm line-clamp-1">
    //             {truncate(data[data.columnKey])}
    //           </span>
    //         </Skeleton.Loader>
    //       </div>
    //       <Skeleton.Loader className="w-[50px]" containerClassName="h-5">
    //         <span className="text-xs text-offWhite">Block Author</span>
    //       </Skeleton.Loader>
    //     </div>
    //   ),
    // },
  ];
};

export default LatestBlockColumn;
